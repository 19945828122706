import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import Slider from '../components/slider/slider';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

// import aboutBgImage from '../images/pages/about/about-banner.png';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}


const AboutUs = ({ data }) => {
  const { pageTitle, 
          metaTitle,
          aboutBannerBgImage,
          descriptionTitle,
          aboutLeftColumnText,
          aboutRightColumnText

  } = data.contentfulAboutUs;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero-shallow" style={{backgroundImage:`url(${ aboutBannerBgImage.file.url })`}}>
          <div className="hero-overlay"></div>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="hero-text-container">
                    <h1>{pageTitle}</h1>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="contact-us full-width-layout">
          <Container fluid={true}>
            <h2>{descriptionTitle}</h2>
            <Row>
              <Col sm={12} lg={6}>
                {documentToReactComponents(aboutLeftColumnText.json, options)}
              </Col>
              <Col sm={12} lg={6}>
                {documentToReactComponents(aboutRightColumnText.json, options)}
              </Col>
            </Row>
          </Container>
        </div>
        <Slider />
    </Layout>
  );
};

export default AboutUs;

export const pageQuery = graphql`
  query {
    contentfulAboutUs {
      aboutBannerBgImage {
        file {
          url
        }
      }
      descriptionTitle
      aboutLeftColumnText {
        json
      }
      aboutRightColumnText {
        json
      }
      metaTitle
      pageTitle
      slug
    }
  }
`;